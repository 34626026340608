<template>
  <component :is="accountingfileData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No accountingFile found -->
    <b-alert variant="danger" :show="accountingfileData === undefined">
      <h4 class="alert-heading">Erreur lors de chargement du contenu</h4>
      <div class="alert-body">
        Aucun element trouvé avec cet identifiant. Vérifier
        <b-link class="alert-link" :to="{ name: 'accountingfile-list' }">
          Liste des elements
        </b-link>
        pour les autres element.
      </div>
    </b-alert>

    <data-add-tab
      :accountingfile-data="accountingfileData"
      :defaultaccountingfile-data="defaultaccountingfileData"
      class="mt-2"
    />
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BAlert,
  BLink,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import storeModule from "./storeModule";
import DataAddTab from "./FormDataParam.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormCheckbox,
    DataAddTab,
    BButton, 
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
    };
  },
  mounted() {
    if (router.currentRoute.name == "accountingfile-param") {
      this.operationTitle = "Parametrage du ";
      this.operationIcon = "ToolIcon";
    }
  },
  methods: {
    prevPage() {
      router.push({ name: "accountingfile-list" });
    },
    convertBoolToInt(value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    let accountingfileData = ref(null);
    let APP_STORE_MODULE_NAME = "app-accountingfile";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    var defaultaccountingfileData = ref({
      budget: "",
      currency: "", 
      advanced: "",  
    }); 

    store
        .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
          id: router.currentRoute.params.id,
        })
      .then((response) => {

          const data_to_update = response.data; 
        
          data_to_update.budget   = JSON.parse(response.data.budget); 
          data_to_update.currency = JSON.parse(response.data.currency); 
          data_to_update.advanced = JSON.parse(response.data.advanced); 
          accountingfileData.value  = data_to_update;

        })
        .catch((error) => {
          console.log(error);
          accountingfileData.value = undefined;
        }); 

    return {
      accountingfileData,
      defaultaccountingfileData,
    };
  },
};
</script>
